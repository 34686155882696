//! set font variables
$font-heading: 'Georgia', serif;
$font-body: 'Source Sans Pro', sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 1.7rem;
  font-weight: 400;
}
