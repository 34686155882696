.photo {
  max-width: 100%;
  max-height: 80%;
  padding-top: 5%;
  padding-bottom: 0;
  object-fit: contain;
  object-position: bottom;
}

.photo__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
}

.photo__text {
  max-width: 80%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2rem;
}
